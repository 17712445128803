<template>
    <v-app>
        <v-main>
            <router-view />
        </v-main>
        <v-dialog
            v-model="inactivityDialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="py-1">
                    {{ $t('inactivityWindow-message') }}
                </v-card-title>
  
                <v-card-text>
                    {{ $t('inactivityWindow-warning') }} {{ countdown }} {{ $t('inactivityWindow-seconds') }}
                </v-card-text>
  
                <v-card-actions>
                    <v-spacer />
  
                    <v-btn
                        :to="{ name: 'LogOut' }"
                    >
                        {{ $t('forcepasswordchange-logoutbuttontext') }}
                    </v-btn>
  
                    <v-btn
                        @click="resetInactivityCountdown"
                    >
                        {{ $t('inactivityWindow-stay') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    components: {
    },
    data() {
        return {
            //Dialog: inactivity
            inactivityDialog: false,
            inactivityTimer: null,
            inactivityCountdown: null,
            countdown: 0,

        }
    },
    computed: {
        color(){
            return process.env.VUE_APP_COMPANY_COLOR
        },
        img(){
            return process.env.VUE_APP_COMPANY_LOGO
        },
    },
    watch:{
        inactivityDialog(val) {
            if(val){
                this.setInactivityCountdown() 
            }
        }
    },
    created() {
        let intervalContainer = setInterval(() => {
            this.RefreshTokenInterval({
                refreshToken: localStorage.getItem('refreshtoken')
            })
        }, 840000)

        this.setIntervalContainer(intervalContainer)
        this.setInactivityTimer()
    },
    methods: {
        setInactivityTimer(){
            this.inactivityTimer = setTimeout(() => {
                this.inactivityDialog = true                           
            }, 840000)            
        },
        resetInactivityTimer(){
            clearTimeout(this.inactivityTimer)
            this.setInactivityTimer()
        },
        setInactivityCountdown(){
            this.countdown = 10

            this.inactivityCountdown = setInterval(() => {
                if(this.countdown > 0){
                    this.countdown = this.countdown - 1                    
                }
                else {
                    clearInterval(this.inactivityCountdown)

                    window.removeEventListener('keyup', this.resetInactivityTimer)
                    window.removeEventListener('mousedown', this.resetInactivityTimer)

                    this.LogOut()
                    this.$router.push({ name: 'Login' })
                }                
            }, 1000)
        },
        resetInactivityCountdown(){
            clearInterval(this.inactivityCountdown)
            this.resetInactivityTimer()
            this.inactivityDialog = false
        },
        ...mapMutations({
            setIntervalContainer: 'setIntervalContainer',
            setSelectedPNumber: 'setSelectedPNumber',
            setSelectedPNumberName: 'setSelectedPNumberName'
        }),
        ...mapActions({
            LogOut: 'LogOut',
            RefreshTokenInterval: 'RefreshTokenInterval',   
        })
    }
}
</script>