import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VMain,[_c('router-view')],1),_c(VDialog,{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.inactivityDialog),callback:function ($$v) {_vm.inactivityDialog=$$v},expression:"inactivityDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.$t('inactivityWindow-message'))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('inactivityWindow-warning'))+" "+_vm._s(_vm.countdown)+" "+_vm._s(_vm.$t('inactivityWindow-seconds'))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"to":{ name: 'LogOut' }}},[_vm._v(" "+_vm._s(_vm.$t('forcepasswordchange-logoutbuttontext'))+" ")]),_c(VBtn,{on:{"click":_vm.resetInactivityCountdown}},[_vm._v(" "+_vm._s(_vm.$t('inactivityWindow-stay'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }